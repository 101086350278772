@font-face {
    font-family: 'RalewayRegular';
    src: url('./assets/fonts/Raleway-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'RalewayBold';
    src: url('./assets/fonts/Raleway-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'MontserratRegular';
    src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'OnestBold';
    src: url('./assets/mems/fonts/OnestBlack1602-hint.ttf') format('truetype');
}

@font-face {
    font-family: 'OnestMedium';
    src: url('./assets/mems/fonts/OnestMedium1602-hint.ttf') format('truetype');
}

@font-face {
    font-family: 'OnestRegular';
    src: url('./assets/mems/fonts/OnestRegular1602-hint.ttf') format('truetype');
}

:root {
    --color-main: #323232;
    --color-accent: #ee6030;
    --color-accent-hover: #ff8860;
    --color-white: #ffffff;
    --color-blue: #0a58ca;
    --color-grey: #b5b5b5;
    --color-grey-7a: #7a7a7a;
    --color-grey-a3: #a3a3a3;
    --color-disabled: #dddddd;
    --color-dark-blue: #113cf2;
    --color-red: #e04416;
    --color-black: #222222;
    --pl-onboarding: 16rem; // padding left for onboarding page
    --color-error: #f9d9d0;
    --color-error-font: #ad3511;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: RalewayRegular;
    margin: 0;
}

html,
body {
    height: 100%;
}

.empty-btn {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
}

.title {
    font-family: MontserratBold;
    font-size: 3rem;
    line-height: 3.6rem;
    text-align: center;
    margin-top: 0;
    padding-top: 2rem;
    &__black {
        color: var(--color-main);
    }
    &__white {
        color: var(--color-white);
    }
    &__accent {
        color: var(--color-accent);
    }
}

.main-button {
    border-radius: 10px;
    background-color: var(--color-accent);
    font-family: RalewayBold;
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;
    color: var(--color-white);
    padding: 1.5rem 3rem 1.5rem 3rem;
    border: none;
    cursor: pointer;
    margin: 1rem 0;

    &__black {
        background: var(--color-main);
    }
}

.main-button:hover {
    background-color: var(--color-accent-hover);
}

.main-button:disabled {
    background-color: var(--color-disabled);
    cursor: auto;
}

.modal-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 201;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    transition: opacity 0.15s linear;
}

.modal__close-btn {
    position: absolute;
    top: 3rem;
    right: 2rem;
    line-height: 2rem;
    border: none;
    background: no-repeat;
    color: var(--color-accent);
    font-size: 2rem;
    cursor: pointer;
}

.modal-fade {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
}

.modal-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        span {
            font-size: 0.875rem;
            text-align: end;
        }
    }
    &__text,
    input::placeholder {
        font-size: 1.125rem;
        font-family: RalewayRegular, sans-serif;
        color: var(--color-grey);
    }
    &__item {
        border: none;
        border-bottom: 0.0625rem solid var(--color-grey);
        outline: none;
        font-size: 1.125rem;
        line-height: 2.75rem;
        font-family: RalewayRegular, sans-serif;
    }
    input {
        margin: 1.5rem 0rem 1.25rem 0rem;
    }
}

.modal-footer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.modal-consent {
    display: grid;
    grid-template-columns: 1.25rem 1fr;
    column-gap: 1rem;
    &__text {
        font-family: 'RalewayRegular';
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: var(--color-main);
        margin: 0;
        button,
        a {
            font-size: 0.875rem;
            line-height: 1.25rem;
            cursor: pointer;
            border: none;
            outline: none;
            background: none;
            padding: 0;
            color: var(--color-accent);
        }
    }

    &__checkbox {
        margin-top: 0.375rem;
        position: relative;
        background: #ffffff;
        border: 2px solid #a3a3a3;
        border-radius: 4px;
        width: 1.25rem;
        height: 1.25rem;
        &--selected {
            display: flex;
            border: none;
            background: var(--color-accent);
        }
        &--selected::after {
            content: '';
            position: absolute;
            border-color: white;
            border-style: solid;
            border-width: 0 0.1875rem 0.1875rem 0;
            height: 0.5rem;
            bottom: 0.375rem;
            right: 0.375rem;
            transform: rotate(45deg);
            width: 0.25rem;
        }
    }
}

@media screen and (max-width: 1599px) {
    html {
        font-size: 14px;
    }
    :root {
        --pl-onboarding: 12rem;
    }
}

@media screen and (max-width: 1439px) {
    html {
        font-size: 13px;
    }
    :root {
        --pl-onboarding: 10rem;
    }
}

@media screen and (max-width: 1330px) {
    html {
        font-size: 12px;
    }
    :root {
        --pl-onboarding: 6rem;
    }
}
@media screen and (max-width: 1140px) {
    html {
        font-size: 12px;
    }
    :root {
        --pl-onboarding: 4rem;
    }
}
@media screen and (max-width: 1024px) {
    :root {
        --pl-onboarding: 1.25rem;
    }
}
@media screen and (max-width: 990px) {
    html {
        font-size: 10px;
    }
}
@media screen and (max-width: 770px) {
    html {
        font-size: 14px;
    }
    .modal-footer {
        flex-direction: column;
    }
}
@media screen and (max-width: 600px) {
    .title {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}
